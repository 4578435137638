import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Brand } from '@resolvers/brand.resolver';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClubsService {
  private http = inject(HttpClient);
  constructor() {
    console.log('ClubsService');
  }

  getBranding(club_id: number): Observable<Brand> {
    return this.http.get<Brand>(`api/clubs/${club_id}/branding`);
  }
}
