import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { ServerAuthInterceptor } from '@interceptors/server-auth.interceptor';
import { ArrangementService } from './arrangement.service';
import { ExceptionsService } from './exceptions.service';
import { MemberService } from './member.service';
import { ClubsService } from './clubs.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerAuthInterceptor,
      multi: true,
    },
    // {
    //     provide: AdminService,
    //     useClass: AdminService,
    // },
    { provide: ArrangementService, useClass: ArrangementService },
    { provide: ClubsService, useClass: ClubsService },
    {
      provide: ExceptionsService,
      useClass: ExceptionsService,
    },
    { provide: MemberService, useClass: MemberService },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class ServiceModule {}
