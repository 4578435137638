import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { Brand } from '@resolvers/brand.resolver';
import { MemberService } from '@services/member.service';
import { Club } from '@models/admin-models';
import { NgClass, AsyncPipe, CurrencyPipe, DatePipe } from '@angular/common';

const club = 1; //TODO: add value to URL and replace with value from URL

@Component({
    selector: 'app-payment-complete',
    templateUrl: './payment-complete.component.html',
    styleUrls: ['./payment-complete.component.less'],
    standalone: true,
    imports: [
    NgClass,
    AsyncPipe,
    CurrencyPipe,
    DatePipe
],
})
export class PaymentCompleteComponent implements OnInit {
  data!: Observable<any>;
  $branding: Observable<Brand>;
  $club: Observable<Club>;
  $payInfo: Observable<any>;
  constructor(route: ActivatedRoute, svc: MemberService) {
    this.$branding = route.data.pipe(map((v) => v['brand']));
    this.$club = route.data.pipe(map((v) => v['clubInfo']));
    this.$payInfo = combineLatest([
      route.data,
      route.params,
      route.queryParams,
    ]).pipe(
      map(([data, params, query]) => Object.assign(data, params, query)),
      mergeMap((r) => {
        if (r['mode'] == 'confirmed') {
          return svc
            .checkPayment(r['club'] ?? club, r['cust'])
            .pipe(map((cp) => Object.assign(cp, r)));
        }
        if (r['mode'] == 'nothing') {
          return svc
            .memberBasic(r['club'] ?? club, r['cust'])
            .pipe(map((cp) => Object.assign(cp, r)));
        }
        return svc.getSchedule(r['club'] ?? club, r['cust']).pipe(
          map((cp) => Object.assign({}, r, cp)),
          tap((v) => console.log('cancelled', v))
        );
      })
    );
  }

  ngOnInit(): void {}

  total(payments: { amount: number }[]) {
    return payments.reduce((s, c) => Math.round((s + c.amount) * 100) / 100, 0);
  }
}
