import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ClubsService } from '@services/clubs.service';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

export type Brand = { crest: string };

@Injectable({ providedIn: 'root' })
export class BrandResolver  {
  constructor(private clubSvc: ClubsService) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Brand | Observable<Brand> | Promise<Brand> {
    const club_id = Number(route.params['club']);
    return this.clubSvc.getBranding(club_id).pipe(first());
  }
}
