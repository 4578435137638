import { NgModule } from '@angular/core';
import {
  RouterModule,
  Routes,
  provideRouter,
  withComponentInputBinding,
} from '@angular/router';
import { BrandResolver } from '@resolvers/brand.resolver';
import { ClubResolver } from '@resolvers/club.resolver';
import { LoggedOutComponent } from './core/logged-out/logged-out.component';
import { PaymentCompleteComponent } from './core/payment-complete/payment-complete.component';

const app_routes: Routes = [
  { path: '', pathMatch: 'full', component: LoggedOutComponent },
  { path: '1/new-member', redirectTo: '/member/1/new' },
  { path: '4/new-member', redirectTo: '/member/4/new' },
  { path: 'logged-in', component: LoggedOutComponent, data: { toAdmin: true } },
  { path: 'log-out', component: LoggedOutComponent, data: { logout: true } },
  { path: 'banking', redirectTo: '/admin/banking' },
  {
    path: 'payment-complete/:club/:cust',
    component: PaymentCompleteComponent,
    data: { mode: 'confirmed' },
    resolve: { brand: BrandResolver, clubInfo: ClubResolver },
  },
  {
    path: 'payment-cancelled/:club/:cust',
    component: PaymentCompleteComponent,
    data: { mode: 'cancelled' },
    resolve: { brand: BrandResolver, clubInfo: ClubResolver },
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'member',
    loadChildren: () =>
      import('./member/member.module').then((m) => m.MemberModule),
  },
];

@NgModule({
  imports: [],
  providers: [provideRouter(app_routes, withComponentInputBinding())],
  exports: [RouterModule],
})
export class AppRoutingModule {}
