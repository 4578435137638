@if ($branding | async; as brand) {
  <img
    [src]="brand.crest"
    alt="Club Crest"
    width="100px"
    style="margin: 10px"
    />
}
@if ($club | async; as clubInfo) {
  <div>
    @if ($payInfo | async; as staticInfo) {
      @if (staticInfo?.mode == 'nothing') {
        <div class="nothing">
          <p>
            You have nothing to pay for your {{ staticInfo.category }} membership.
          </p>
          @if (staticInfo.billInfo != null) {
            <p>
              Your latest bill, dated
              {{ staticInfo.billInfo.dateISO | date: "dd/MM/yyyy" }} has an
              outstanding balance of {{ staticInfo.billInfo.Balance }} and your
              account balance is {{ staticInfo.latestBalance | currency: "GBP" }}.
            </p>
          }
        </div>
      }
      @if (staticInfo?.mode == 'already') {
        <div class="confirmed">
          <div>
            @switch (staticInfo.mostRecent?.metadata?.mode) {
              @case ('sub') {
                <div>
                  You have already set up a valid subscription for your
                  {{ staticInfo.membership.title }}. Your remaining outstanding balance
                  is {{ staticInfo.outstanding | currency: "GBP" }} and no further
                  payment is required at this time.
                </div>
              }
              @case ('full') {
                <div>
                  You have already completed payment for your
                  {{ staticInfo.membership.title }}. Your outstanding balance is
                  {{ staticInfo.outstanding | currency: "GBP" }} and no further payment
                  is required at this time.
                </div>
              }
              @default {
                <div>
                  You have already completed payment for your
                  {{ staticInfo.membership.title }}. Your outstanding balance is
                  {{ staticInfo.outstanding | currency: "GBP" }} and no further payment
                  is required at this time.
                </div>
              }
            }
          </div>
          @if (staticInfo.mostRecent?.receipts?.length > 0) {
            <div>
              Your receipt for your most recent payment can be viewed
              @for (receipt of staticInfo.mostRecent?.receipts; track receipt) {
                <a
                  [href]="receipt"
                  >here</a
                  >
                }
              </div>
            }
          </div>
        }
        @if (staticInfo?.mode == 'pending') {
          <div class="confirmed">
            <div>
              @switch (staticInfo.mostRecent?.metadata?.mode) {
                @case ('sub') {
                  <div>
                    You have already set up a valid subscription for your
                    {{ staticInfo.membership.title }}. Your remaining outstanding balance
                    is {{ staticInfo.outstanding | currency: "GBP" }} and no further
                    payment is required at this time.
                  </div>
                }
                @case ('full') {
                  <div>
                    A full payment is currently pending for your
                    {{ staticInfo.membership.title }}. Your outstanding balance is currently
                    {{ staticInfo.outstanding | currency: "GBP" }} but no further payment
                    action is required at this time.
                  </div>
                }
                @default {
                  <div>
                    You have already completed payment for your
                    {{ staticInfo.membership.title }}. Your outstanding balance is
                    {{ staticInfo.outstanding | currency: "GBP" }} and no further payment
                    is required at this time.
                  </div>
                }
              }
            </div>
            @if (staticInfo.mostRecent?.receipts?.length > 0) {
              <div>
                Your receipt for your most recent payment can be viewed
                @for (receipt of staticInfo.mostRecent?.receipts; track receipt) {
                  <a
                    [href]="receipt"
                    >here</a
                    >
                  }
                </div>
              }
            </div>
          }
          @if (staticInfo?.mode == 'confirmed') {
            <div class="confirmed">
              <h2>{{ clubInfo.name }} Membership Payment Confirmed</h2>
              <p>Thank you for completing payment of your membership subscription.</p>
              <p>
                For a printable receipt of payment, click
                <a [href]="staticInfo.payment.receipt_url" target="_blank_">here</a>
              </p>
              @if (staticInfo?.billable?.member?.schedule != null) {
                <div>
                  <h2>Payment Schedule</h2>
                  <p>
                    In order to complete your membership payments, the following payments
                    will be taken on the first banking day on or after the dates below:
                  </p>
                  <table>
                    <thead>
                      <tr>
                        <td>Description</td>
                        <td>Date</td>
                        <td class="cash">Amount</td>
                      </tr>
                    </thead>
                    <tbody>
                      @for (
                        item of staticInfo?.billable?.member?.schedule?.payments
                        ; track
                        item) {
                        <tr
                          >
                          @if (item.label) {
                            <td class="part">{{ item.label }}</td>
                          }
                          @if (item.cycle) {
                            <td class="part">Month {{ item.cycle }}</td>
                          }
                          @if (item.label) {
                            <td class="part">paid</td>
                          }
                          @if (item.cycle) {
                            <td class="part">{{ item.date | date }}</td>
                          }
                          <td class="cash" [ngClass]="{ complete: item.label != null }">
                            {{ item.amount | currency: "GBP" }}
                          </td>
                        </tr>
                      }
                      <tr>
                        <td><b>Total (incl. already paid)</b></td>
                        <td></td>
                        <td class="cash total">
                          {{
                          total(staticInfo?.billable?.member?.schedule?.payments)
                          | currency: "GBP"
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              }
            </div>
          }
          @if (staticInfo?.mode == 'cancelled' && staticInfo.bill_entry != null) {
            <div
              class="cancelled"
              >
              <h2>{{ clubInfo.name }} Membership Payment Cancelled</h2>
              <p>
                You cancelled your payment for your
                {{ staticInfo.bill_entry.category }} membership.
              </p>
              <p>
                Membership subscriptions for season 2022 are due for renewal. Our
                records indicate that your membership and other costs for this period
                amount to
                <span class="cash">{{ staticInfo.outstanding | currency: "GBP" }}</span>
              </p>
              @if (staticInfo.bill_entry?.subscription) {
                <p>
                  This value is made up of:
                </p>
              }
              @if (staticInfo.bill_entry?.subscription) {
                <table>
                  <thead>
                    <tr>
                      <td class="part">Item</td>
                      <td class="cash">Value</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="part">
                        Membership - {{ staticInfo.bill_entry.category }}
                      </td>
                      <td class="cash">
                        {{ staticInfo.bill_entry.subscription | currency: "GBP" }}
                      </td>
                    </tr>
                    @for (fee of staticInfo.bill_entry.fees_detailed.breakdown; track fee) {
                      <tr>
                        <td class="part">{{ fee.name }}</td>
                        <td class="cash">{{ fee.value | currency: "GBP" }}</td>
                      </tr>
                    }
                    @if (staticInfo.bill_entry.locker) {
                      <tr>
                        <td class="part">Locker</td>
                        <td class="cash">
                          {{ staticInfo.bill_entry.locker | currency: "GBP" }}
                        </td>
                      </tr>
                    }
                    @if (staticInfo.bill_entry.vat) {
                      <tr>
                        <td class="part">VAT</td>
                        <td class="cash">
                          {{ staticInfo.bill_entry.vat | currency: "GBP" }}
                        </td>
                      </tr>
                    }
                    @if (staticInfo.bill_entry.swipe_card) {
                      <tr>
                        <td class="part">Swipe Card</td>
                        <td class="cash">
                          {{ staticInfo.bill_entry.swipe_card | currency: "GBP" }}
                        </td>
                      </tr>
                    }
                    @if (staticInfo.bill_entry.already_paid) {
                      <tr>
                        <td class="part">Amounts paid</td>
                        <td class="cash">
                          ({{ staticInfo.bill_entry.already_paid | currency: "GBP" }})
                        </td>
                      </tr>
                    }
                    @if (staticInfo.bill_entry.brought_forward) {
                      <tr>
                        <td class="part">Amounts carried forward</td>
                        <td class="cash">
                          {{ staticInfo.bill_entry.brought_forward | currency: "GBP" }}
                        </td>
                      </tr>
                    }
                    @if (staticInfo.bill_entry.outstanding) {
                      <tr>
                        <td><b>Total</b></td>
                        <td class="cash total">
                          {{ staticInfo.bill_entry.outstanding | currency: "GBP" }}
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>
              }
              <p>
                The club incurs considerable effort and cost to administer, monitor and
                manage membership subscriptions. In order to make this simpler and
                cheaper for both the club and members, we would prefer electronic
                payment via the buttons below.
              </p>
              <div class="options">
                <p>
                  <b
                    >Note: In order to retain continuous membership, subscriptions
                    should either be payed in full or a financial agreement be in place,
                    with deposit paid.</b
                    >
                  </p>
                  <p>
                    Option 1. Pay in full (via Direct Debit or Debit/Credit card). This is
                    the club's preferred option, particularly using Direct Debit, and the
                    full amount of
                    <span class="cash total">{{
                      staticInfo.bill_entry.outstanding || staticInfo.outstanding
                      | currency: "GBP"
                    }}</span>
                    @if (staticInfo.immediateDiscount) {
                      <span>
                        less an immediate payment discount of
                        <span class="cash total">{{
                          staticInfo.immediateDiscount | currency: "GBP"
                        }}</span></span
                        >
                      }
                      will be taken when you complete the payment form.
                    </p>
                    @if (staticInfo.bill_member_jwt) {
                      <p>
                        <a
                          class="pay-link"
                          href="/api/payments/redirect_jwt/{{ staticInfo.bill_member_jwt }}"
                          >Click here to pay in full</a
                          >
                        </p>
                      }
                      @if (staticInfo.model == 'new' && !staticInfo.noSchedule) {
                        <div>
                          <p>
                            Option 2. Pay over a number of months (via Direct Debit or
                            Debit/Credit card).
                          </p>
                          <p>This is the club's new interest free monthly payment option.</p>
                          <p>
                            <a
                              class="pay-link"
              href="/api/payments/redirect_jwt/{{
                staticInfo.bill_member_jwt
              }}?mode=sub"
                              >Click here to pay monthly</a
                              >
                            </p>
                          </div>
                        }
                        <p>
                          Option
                          @if (
                            staticInfo.bill_entry.schedule || staticInfo.noSchedule === false
                            ) {
                            <span
                              >3</span
                              >
                            }
                            @if (staticInfo.noSchedule) {
                              <span>2</span>
                              }. For members with
                              existing Standing Order arrangements, please amend your payment
                              reference to your new unique account identifier:
                              <b
                                >PNP{{ staticInfo.bill_entry.rec_no }}-{{
                                staticInfo.bill_entry.member.id
                                }}</b
                                >
                              </p>
                              <div class="direct">
                                <p>
                                  Members wishing to pay via bank Electronic Funds Transfer, please
                                  use the following details:
                                </p>
                                <div>
                                  <i>Payee: </i>
                                  <b>{{ staticInfo.club.banking.name || staticInfo.club.name }}</b>
                                </div>
                                <div>
                                  <i>Sort Code: </i> <b>{{ staticInfo.club.banking.sort }}</b>
                                </div>
                                <div>
                                  <i>Account Number: </i><b>{{ staticInfo.club.banking.account }}</b>
                                </div>
                                <div>
                                  <i>Reference: </i>
                                  <b
                                    >PNP{{ staticInfo.bill_entry.rec_no }}-{{
                                    staticInfo.bill_entry.member.id
                                    }}</b
                                    >
                                  </div>
                                  <p>
                                    Members wishing to pay in full in person can also do so via cash,
                                    card or cheque in full at the pro shop.
                                  </p>
                                </div>
                              </div>
                            </div>
                          }
                        }
                      </div>
                    }
