import { enableProdMode, importProvidersFrom } from '@angular/core';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { AuthModule } from '@auth0/auth0-angular';
import { ServiceModule } from '@services/service.module';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app/app-routing.module';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  provideMomentDateAdapter,
} from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS } from '@angular/material/core';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      AppRoutingModule,
      ServiceModule,
      AuthModule.forRoot({
        domain: 'nooq.eu.auth0.com',
        clientId: 'wMnhXDmCDH27m1TvCz2LBItDyRN0RXa4',
        useRefreshTokens: true,
        cacheLocation: 'localstorage',
        httpInterceptor: { allowedList: ['https://localhost'] },
        authorizationParams: {
          redirectUri: 'https://localhost/logged-in',
        },
      })
    ),
    provideAnimations(),
    provideMomentDateAdapter(),
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'DD/MM/YYYY',
        },
        display: {
          dateInput: 'DD/MM/YYYY',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'DD/MM/YYYY',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      },
    },
  ],
}).catch((err) => console.error(err));
